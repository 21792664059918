import { ILineChartProps, LineChart } from "@fluentui/react-charting";
import React from "react";
import { IResizeLineChartProps } from "../interfaces/resize-linechart-props.interfaces";
import { format } from "d3-format";

export class LineChartComponent extends React.Component<IResizeLineChartProps, ILineChartProps> {
  private tickValues: Date[] = [];
  constructor(props: IResizeLineChartProps) {
    super(props);
    this.state = {
      width: props.width,
      height: props.height,
      allowMultipleShapesForPoints: !!props.allowMultipleShapesForPoints,
      data: props.data,
      xAxisTitle: props.xAxisTitle,
      yAxisTitle: props.yAxisTitle
    };
    setTimeout(() => {
      const grigrpElements = document.querySelectorAll("[role='listbox']");
      grigrpElements.forEach(function (element) {
        element.setAttribute("aria-busy", "true");
      });
    }, 1000);
  }

  componentDidUpdate(prevProps: ILineChartProps, prevState: ILineChartProps) {
    if (!this.props.allowResize) return;
    if (this.props.width !== prevProps.width || this.state.width !== prevState.width) {
      this._onWidthChange(this.props.width);
    }
  }

  public render(): React.JSX.Element {
    this.tickValues = this._getTickValues();
    return <div>{this._basicExample()}</div>;
  }

  private _onWidthChange = (width?: number) => {
    this.setState({ width });
  };

  private _onHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ height: parseInt(e.target.value, 10) });
  };

  private formatBillions = (fs: any) => (s: any) => format(fs)(s).replace(/G/, "B");

  private _showNoGraphData(): React.JSX.Element {
    const rootStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "inherit"
    };
    return <div style={rootStyle}>Graph has no data to display</div>;
  }

  private _getTickValues = (): Date[] => {
    const dts = new Set<any>([]);
    this.props.data.lineChartData?.map((m) => m.data.map((d) => dts.add((d.x as Date).toISOString())));
    return Array.from(dts).map((m) => new Date(m));
  };

  private _basicExample(): React.JSX.Element {
    const rootStyle = {
      width: `${this.state.width}px`,
      height: `${this.state.height}px`
    };

    return (
      <div style={rootStyle}>
        {(!this.props.data.lineChartData && this._showNoGraphData()) || (
          <LineChart
            legendsOverflowText={"Overflow Items"}
            height={this.state.height}
            width={this.state.width}
            tickFormat={"%b %y"}
            tickValues={this.tickValues}
            allowMultipleShapesForPoints={this.state.allowMultipleShapesForPoints}
            enablePerfOptimization={true}
            strokeWidth={2}
            yAxisTitle={this.state.yAxisTitle}
            xAxisTitle={this.state.xAxisTitle}
            yAxisTickFormat={this.formatBillions("0.2s")}
            rotateXAxisLables={true}
            legendProps={{
              canSelectMultipleLegends: true,
              allowFocusOnLegends: true
            }}
            useUTC={true}
            {...this.props}
          />
        )}
      </div>
    );
  }
}
